<template>
  <!-- 虚拟仿真实验 -->
  <div class="virtualsimulationexperiment">
    <router-view></router-view>
  </div>
</template>

<script>
import Mainpage from "@/views/virtualsimulationexperiment/components/mainpage.vue";
export default {
  name: "Virtualsimulationexperiment",
  components: {
    Mainpage,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.virtualsimulationexperiment {
  //  border: 1px solid red;
  background: #ffffff;
  // display: flex;
  // justify-content: center;
  // align-items: center;
}
</style>
